<template>
    <div class="container">
        <div class="row">
            <div class="col">
                <ul class="partner-list">
                    <li
                        v-for="(item, index) in getSelectedCategories"
                        v-bind:key="'selected-' + index"
                    >
                        <div class="box">
                            <div class="title">{{ item.name }}</div>
                            <router-link
                                v-if="item.children && item.children.length > 0"
                                :to="{
                                    name: 'Home2',
                                    params: { categoryId: item.id }
                                }"
                                class="stretched-link"
                            ></router-link>
                            <router-link
                                v-else
                                :to="{
                                    name: 'Category',
                                    params: { id: item.id }
                                }"
                                class="stretched-link"
                            ></router-link>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import ApiService from "../core/services/api.service";

export default {
    name: "Home2",
    data() {
        return {
            categories: [],
            founded: null
        };
    },
    components: {},
    beforeMount() {
        this.getCategories();
    },
    watch: {
        $route: function() {
            this.getCategories();
        }
    },
    computed: {
        getSelectedCategories: function() {
            if (this.categories.length < 1) {
                return [];
            }
            if (this.$route.params.categoryId) {
                this.recursiveSearch(
                    this.categories,
                    this.$route.params.categoryId
                );
                console.log("found");
                return this.founded.children;
            } else {
                return this.categories;
            }
        }
    },
    methods: {
        recursiveSearch(element, id) {
            for (const it in element) {
                console.log(element[it].name);
                if (parseInt(element[it].id) === parseInt(id)) {
                    console.log("talált ág");
                    this.founded = element[it];
                    return element[it];
                } else {
                    if (element[it].children.length > 0) {
                        console.log("recvursive ág");
                        this.recursiveSearch(element[it].children, id);
                    }
                }
            }
        },
        getCategories() {
            ApiService.get(`/category/get-categories`).then(response => {
                this.categories = response.data.data;
            });
        }
    }
};
</script>

<style scoped></style>
