import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Home2 from "../views/Home2";

Vue.use(VueRouter);

const routes = [
    {
        path: "/:categoryId?",
        name: "Home",
        component: Home
    },
    {
        path: "/:categoryId?",
        name: "Home2",
        component: Home2
    },
    {
        path: "/rolunk",
        name: "About",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/About.vue")
    },
    {
        path: "/adatvedelem",
        name: "Privacy",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "privacy" */ "../views/Privacy.vue")
    },
    {
        path: "/kapcsolat",
        name: "Contact",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "contact" */ "../views/Contact.vue")
    },
    {
        path: "/regisztracio",
        name: "Register",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "register" */ "../views/Register.vue")
    },
    {
        path: "/kategoria/:id",
        name: "Category",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "category" */ "../views/Category.vue")
    },
    {
        path: "/partner/:id",
        name: "Partner",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "partner" */ "../views/Partner.vue")
    }
];

const router = new VueRouter({
    mode: "history",
    base: "/",
    routes
});

export default router;
